.ant-btn {
  min-width: 80px;
}
.ant-btn-primary {
  box-shadow: unset;
}

.ant-table-wrapper {
  .ant-table {
    line-height: unset;
  }
  .ant-table-thead > tr > th {
    font-weight: 400;
    border-bottom: 0px;
  }
}

.ant-modal {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    height: 50px;
    line-height: 50px;
    background-color: #F4F6F8;
  }
  .ant-modal-title {
    font-weight: 500;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    padding-left: 24px;
  }
  .ant-modal-close {
    top: 14px;
    color: #666A77;
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-footer {
    height: 60px;
    padding: 0px 24px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: end;
    box-shadow: inset 0px 1px 0px 0px #EBEDF0;
  }
}

.ant-pagination {
  color: #666A77;
  font-size: 12px;
  .ant-pagination-prev, .ant-pagination-next {
    border: 1px solid #DFE1E5;
    > button {
      color: #666A77;
    }
  }
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: transparent;
  }
  .ant-pagination-item {
    border-color: #DFE1E5;
    > a {
      color: #666A77;
    }
  }
  .ant-pagination-item-active {
    border-color: #9e896a;
    > a {
      color: #9e896a;
    }
  }
  .ant-select-single .ant-select-selector {
    color: #666A77;
    font-size: 12px;
  }
  .ant-select-dropdown .ant-select-item {
    font-size: 12px;
  }
}

.ant-picker {
  .ant-picker-range-separator {
    padding: 0px 2px;
    .ant-picker-separator {
      display: none;
    }
  }
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 0;
}
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  display: none;
}
.ant-tooltip .ant-tooltip-arrow {
  display: none;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-select,
.ant-select-single .ant-select-selector,
.ant-form label,
.ant-picker,
.ant-picker .ant-picker-input >input,
.ant-form-item .ant-form-item-explain,
.ant-select-dropdown .ant-select-item {
  font-size: 12px;
}


.ant-input-affix-wrapper {
  height: 32px;
}
.ant-input-number {
  height: 32px;
}
.ant-input-number-handler-wrap {
  height: 30px;
}
.ant-input-number-input-wrap, .ant-input-number-input {
  height: 30px;
}
.ant-picker .ant-picker-input >input {
  height: 22px;
  line-height: 22px;
}

.ant-rate {
  color: #40C3A8;
}

.ant-form-item .ant-form-item-label >label::after {
  content: '';
}
