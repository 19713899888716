.coach-list-page {
  .yg-refresh-btn {
    right: 120px;
  }
}

.add-coach-modal {
  .coach-form-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .coach-form-row {
    margin-left: -20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: flex-start;
  }
  .select-btn {
    width: 70px;
    background-color: #FFF8EA;
    border-radius: 2px;
    border: 1px solid #9E896A;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #9E896A;
  }
  .upload-row {
    display: flex;
    flex-direction: row;
  }
  .textarea-row {
    margin-left: -28px;
  }
  .select-btn-wrap {
    display: flex;
    align-items: center;
    .name {
      display: inline-block;
      width: 90px;
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      margin-left: 5px;
    }
  }
  .coach-picture-uploader {
    .ant-upload.ant-upload-select {
      background-color: #FFFAEC;
      border-radius: 6px;
      border: 1px solid #9E896A;
    }
    .anticon-plus {
      color: #815008;
      font-size: 22px;
    }
  }
}

.select-avatar-modal {
  .ant-modal-body {
    height: 422px;
    overflow-y: auto;
    position: relative;
  }
  .ant-pagination {
    position: absolute;
    right: 40px;
    bottom: 18px;
  }
  .photo-section {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }
  .photo-item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 20%;
    margin-bottom: 12px;
    position: relative;
    .img-item {
      cursor: pointer;
      margin-right: 12px;
      height: 100px;
      border-radius: 6px;
    }
    .border-yellow {
      border: 3px solid #FFBD3E;
    }
    .have-select {
      position: absolute;
      top: 6px;
      right: 20px;
      .anticon-check-circle {
        color: #FFBD3E;
      }
    }
  }
}