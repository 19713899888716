.class-list-page {
  .yg-page-main {
    // overflow: hidden;
    // overflow-y: auto;
    min-height: 100%;
  }
  .add-class-btn {
    position: absolute;
    right: 24px;
    bottom: 10px;
  }
  .class-mode-tabs {
    &.ant-tabs >.ant-tabs-nav {
      margin-bottom: 0px;
    }
    &.ant-tabs-top >.ant-tabs-nav::before {
      border: 0;
    }
    &.ant-tabs .ant-tabs-tab {
      padding: 10px 0px;
    }
  }
}