.class-calendar-comp {
  height: 100%;
  width: 100%;
  .start-cell {
    font-size: 12px;
    color: #7E7E7E;
    font-weight: 400;
  }
  .time-cell {
    font-size: 14px;
    color: #212121;
    font-weight: 400;
  }
  .date-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .date-str {
      font-size: 14px;
      color: #7E7E7E;
      font-weight: 400;
    }
    .date-week {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
    }
  }
  .class-cell {
    width: 100%;
  }
  .empty-box {
    height: 120px;
    width: 100%;
  }
  .calendar-table-wrapper {
    height: calc(100% - 50px);
    width: 100%;
    overflow: hidden;
    position: relative;
    .ant-spin-spinning {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.6);
      z-index: 2;
    }
  }
  .calendar-table-with-head-wrapper {
    height: 65px;
    overflow-y: scroll;
  }
  .calendar-table-with-head {
    border-collapse: collapse;
    height: 64px;
    width: 100%;
    position: relative;
    table-layout: fixed;
    word-break: break-all;
  }
  .calendar-table-with-body-wrapper {
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: scroll;
  }
  .calendar-table-with-body {
    table-layout: fixed;
    word-break: break-all;
    border-collapse: collapse;
    width: 100%;
  }
  .calendar-head {
    .calendar-col {
      min-height: 64px;
      height: 64px;
      border-top: 1px solid #E6E6ED;
    }
  }
  .calendar-row {
    width: 100%;
    vertical-align: middle;
  }
  .calendar-col {
    width: calc((100% - 70px) / 7);
    border: 1px solid #E6E6ED;
    border-left: 0;
    border-top: 0;
    text-align: center;
    padding: 8px;
    &:first-child {
      width: 70px;
      border-left: 1px solid #E6E6ED;
    }
  }
  .date-swapper-wrapper {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .year-month-box {
      font-size: 18px;
      font-weight: 500;
      color: #262626;
      margin-right: 14px;
    }
    .swapper-btn-box {
      width: 64px;
      height: 28px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      margin-right: 14px;
      border: 1px solid #E9E9E9;
      .center-line {
        display: inline-block;
        width: 1px;
        height: 8px;
        background-color: #D9D9D9;
      }
      .next-btn, .pre-btn {
        cursor: pointer;
        display: inline-block;
        line-height: 28px;
        height: 28px;
        width: calc((100% - 1px) / 2);
        text-align: center;
        &.disabled {
          cursor: not-allowed;
          color: #D9D9D9;
        }
      }
    }
    .today-btn {
      width: 48px;
      height: 28px;
      border-radius: 6px;
      border: 1px solid #E9E9E9;
      font-size: 12px;
      color: #000000;
      line-height: 28px;
      cursor: pointer;
      text-align: center;
    }
  }
  .class-item {
    width: 100%;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .class-card {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 8px;
    padding: 10px 8px;
    .class-title {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
      width: 100%;
      text-align: center;
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
    .class-coach {
      color: #FFFFFF;
      font-size: 12px;
      width: 100%;
      margin-bottom: 12px;
      text-align: center;
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
    }
    .class-time {
      color: #FFFFFF;
      font-size: 12px;
      width: 100%;
      margin-bottom: 4px;
      text-align: center;
      white-space: nowrap;
    }
    .view-btn {
      height: 22px;
      line-height: 22px;
      background-color: #fff;
      font-size: 12px;
      text-align: center;
      border-radius: 18px;
      cursor: pointer;
      width: calc(100% - 16px);
    }
  }
}
.class-calendar-comp.class-calendar-comp-mini {
  .date-swapper-wrapper {
    height: 22px;
    margin-bottom: 12px;
    .year-month-box {
      font-size: 12px;
      margin-right: 10px;
    }
    .swapper-btn-box {
      height: 22px;
      font-size: 12px;
      width: 46px;
      margin-right: 10px;
    }
    .today-btn {
      height: 22px;
      line-height: 22px;
    }
  }
  .calendar-table-wrapper {
    height: calc(100% - 34px);
  }
  .calendar-table-with-head-wrapper {
    height: 50px;
  }
  .calendar-table-with-head {
    height: 50px;
    // border-bottom: 1px solid #E6E6ED;
  }
  .calendar-table-with-body-wrapper {
    height: calc(100% - 50px);
  }
  .calendar-head {
    .calendar-col {
      min-height: 44px;
      height: 44px;
    }
  }
  .date-cell {
    .date-week, .date-str {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .time-cell {
    font-size: 12px;
  }
  .empty-box {
    height: 102px;
  }
  .calendar-col {
    width: calc((100% - 60px) / 7);
    &:first-child {
      width: 60px;
    }
  }
  .class-card {
    padding: 8px 6px;
    height: 102px;
    .class-title {
      font-size: 12px;
      height: 16px;
      line-height: 16px;
      margin-bottom: 0px;
    }
    .class-coach {
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      margin-bottom: 8px;
    }
    .class-time {
      transform: scale(0.83);
      transform-origin: center center;
      margin-bottom: 0px;
      white-space: nowrap;
    }
  }
   
}