.class-calendar-tab {
  width: 100%;
  display: flex;
  height: 100%;
  .left-calendar-wrapper {
    width: 50%;
    flex: 1;
    height: calc(100vh - 54px - 86px - 24px - 32px - 32px);
    overflow-y: auto;
  }
  .right-calendar-wrapper {
    width: 296px;
    height: 342px;
    border-radius: 16px;
    border: 1px solid #E6E6ED;
    margin-left: 32px;
    overflow: hidden;
    padding: 20px;
    .ant-picker-calendar .ant-picker-panel {
      border-top: 0px;
      .ant-picker-content > thead {
        th {
          padding-bottom: 16px;
          border-bottom: 1px solid #E6E6ED;
        }
      }
      .ant-picker-content > tbody > tr:first-child {
        td {
          padding-top: 16px;
        }
      }
    }
    .right-calendar-header {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      padding: 4px 8px 0px;
      color: #000000;
      margin-bottom: 16px;
      .anticon-icon {
        cursor: pointer;
        font-size: 18px;
      }
    }
  }
}