.add-member-modal {
  .form-bg {
    width: 100%;
    padding: 16px 16px;
    background-color: #FAFAFA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item {
      width: calc((100% - 24px) / 2);
      &:nth-child(2n) {
        margin-left: 24px;
      }
    }
  }
  .without-margin-bottom {
    margin-bottom: 0px;
  }
}