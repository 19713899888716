html, body {
  padding: 0px;
  margin: 0px;
}
li {
  list-style: none;
}
ul, li {
  padding: 0px;
  margin: 0px;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0px;
}
* {
  box-sizing: border-box;
}

#root {
  min-width: 1400px;
}

.yg-scroll-bar{
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
  }
  &:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1);
  }
}

// 表格中的状态列
.yg-status-col {
  display: flex;
  align-items: center;
  &.red {
    color: #FF5B68;
    .yg-status-comma {
      background: #FF5B68;
    }
  }
  &.green {
    color: #40C3A8;
    .yg-status-comma {
      background: #40C3A8;
    }
  }
  &.yellow {
    color: #FFAC2E;
    .yg-status-comma {
      background: #FFAC2E;
    }
  }
  &.blue {
    color: #3A72FF;
    .yg-status-comma {
      background: #3A72FF;
    }
  }
  &.gray {
    color: #C0C3CC;
    .yg-status-comma {
      background: #C0C3CC;
    }
  }
  .yg-status-comma {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 8px;
  }
}

// 表格中的操作列
.yg-operate-col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .yg-operate-btn {
    cursor: pointer;
    color: #815008;
    margin-right: 8px;
    &:last-child {
      margin-right: 0rpx;
    }
    &.disabled {
      cursor: not-allowed;
      color: #bfbfbf;
    }
    &.red {
      color: #FF0E00;
    }
    &.green {
      color: #40C3A8;
    }
  }
}

// 页面样式
.yg-page {
  width: 100%;
  height: 100%;
  &-header {
    background-color: #fff;
    height: 54px;
    width: 100%;
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: inline-block;
      font-weight: 500;
      color: #252A3D;
      font-size: 16px;
      height: 54px;
      line-height: 54px;
    }
  }
  &-filter {
    background-color: #fff;
    width: 100%;
    padding: 0px 24px 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    &-item {
      margin-left: 20px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-left: 0px;
      }
    }
    &-item-right {
      position: absolute;
      right: 24px;
    }
    &-label {
      color: #333333;
      font-size: 14px;
      margin-right: 12px;
    }
  }
  &-container {
    height: calc(100% - 54px - 32px - 16px); // filter 一行 内部高度 32px
    background-color: #F4F4F4;
    padding: 20px 24px 32px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-main {
    border-radius: 8px;
    min-height: 100%;
    background-color: #fff;
    width: 100%;
    padding: 20px;
  }
}

// 查询按钮样式
.yg-ghost-btn {
  background-color: #FFF8EA;
  color: #9e896a;
  border-color: #9e896a;
}

.yg-refresh-btn {
  width: 32px;
  color: #666A77;
  font-size: 14px;
  min-width: unset;
  padding: 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

// 弹框里的小型表格
.yg-small-table.ant-table-wrapper {
  .ant-table {
    font-size: 12px;
  }
  .ant-table-thead > tr > th, .ant-table-cell {
    padding: 8px 10px;
  }
}

// 微信头像展示列
.yg-weixin-url-col {
  display: flex;
  align-items: center;
  > img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

// 当前操作用户信息卡片
.yg-user-info-card {
  background-color: #FAFAFA;
  padding: 20px;
  margin-bottom: 20px;
  .card-title {
    font-size: 12px;
    height: 20px;
    margin-bottom: 12px;
    > span {
      &:first-child {
        color: #8C8C8C;
      }
      &:last-child {
        color: #595959;
        font-weight: 500;
      }
    }
  }
  .info-list {
    display: flex;
    align-items: center;
    .info-item {
      width: calc(100% / 3);
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 12px;
      > span {
        &:first-child {
          margin-right: 12px;
          color: #7E7E7E;
        }
        &:last-child {
          color: #252B3C;
        }
      }
    }
  }
}