.basic-layout-wrapper {
  height: calc(100vh);
  width: 100%;
  display: flex;
  flex-direction: row;
  .logo-wrapper {
    width: 208px;
    height: 54px;
    padding-left: 22px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    > img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }
  .ant-menu-root > li:first-child {
    padding-left: 28px !important;
  }
  .ant-menu.layout-menu-ul {
    li {
      width: 100%;
      // height: 48px;
      padding: 0;
      margin: 0;
    }
    .ant-menu-title-content {
      height: 48px;
      line-height: 48px;
    }
    
    .ant-menu-item:not(.ant-menu-item-selected):active{
      background-color: #9E896A;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .ant-menu-item-selected {
      background-color: #9E896A !important;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
  
  }
  .layout-main-container {
    width: 100%;
    height: 100%;
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background: #F4F4F4;
  }
  .layout-page-container {
    height: calc(100% - 54px - 1px);
    width: 100%;
  }
}

.loading-page-wrapper {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}