.home-page {
  padding: 24px 24px 32px;
  background-color: #F4F4F4;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .link-list {
    display: flex;
    align-items: center;
    justify-content: center;
    .link-item {
      width: calc((100% - 72px) / 4);
      height: 70px;
      margin-right: 24px;
      padding: 0px 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 8px;
      &:last-child {
        margin-right: 0px;
      }
      .link-title {
        font-size: 14px;
        font-weight: 600;
      }
      .link-icon {
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background-color: #FFF6E0;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          height: 20px;
        }
      }
    }
  }
  .center-part {
    display: flex;
    width: 100%;
    height: 236px;
    margin: 24px 0px;
    align-items: center;
    justify-content: center;
    .member-statistic-card {
      height: 100%;
      padding: 20px;
      width: calc((100% - 24px) / 3);
      margin-right: 24px;
      background-image: url(../../assets/images/home_bg.png);
      background-position: center center;
      background-size: 100% 100%;
      border-radius: 8px;
      .new-count-txt {
        font-size: 14px;
        font-weight: 600;
        color: #FFFCFC;
        height: 20px;
        line-height: 20px;
      }
      .new-count-num {
        height: 38px;
        font-size: 32px;
        font-weight: 900;
        color: #FFFFFF;
        line-height: 38px;
        margin: 8px 0px 24px;
      }
      .count-card-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .renew-count-card {
        height: 106px;
        width: calc((100% - 20px) / 8 * 3);
        margin-right: 20px;
        background: rgba(255,255,255,0.65);
        border-radius: 10px;
        padding: 16px;
      }
      .total-count-card {
        width: calc((100% - 20px) / 8 * 5);
        height: 106px;
        background: rgba(255,255,255,0.65);
        border-radius: 10px;
        padding: 16px;
      }
      .count-txt {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }
      .count-num {
        margin-top: 14px;
        height: 38px;
        font-size: 32px;
        font-weight: 900;
        color: #26272A;
        line-height: 38px;
      }
    }
    .appointment-statistic-card {
      padding: 20px;
      width: calc((100% - 24px) / 3 * 2);
      height: 100%;
      background-color: #fff;
      border-radius: 8px;
      .appointment-title {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #666A77;
        line-height: 20px;
        margin-bottom: 8px;
      }
      .appointment-count {
        height: 38px;
        width: fit-content;
        font-size: 32px;
        font-weight: 900;
        color: #26272A;
        line-height: 38px;
        > span:last-child {
          display: inline-block;
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #E89800;
          line-height: 14px;
        }
      }
    }
  }
  .appointment-list {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    .title-box {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #252A3D;
      line-height: 20px;
      margin-bottom: 12px;
      > span {
        font-size: 12px;
        font-weight: 400;
        color: #8C909D;
        margin-left: 20px;
      }
    }
  }
}