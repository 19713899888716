.add-class-modal {
  .gray-bg {
    width: 100%;
    padding: 20px;
    background-color: #FAFAFA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item {
      width: calc((100% - 48px) / 2);
      &:nth-child(2n) {
        margin-left: 48px;
      }
    }
    .ant-form-item-label {
      width: 76px;
      max-width: 76px;
      flex: unset;
    }
  }
  .without-margin-bottom {
    margin-bottom: 0px;
  }
  .one-line-item {
    .ant-form-item-label {
      width: 96px;
      max-width: 96px;
      flex: unset;
    }
  }
}