.class-list-tab {
  .yg-page-filter {
    padding-left: 0px;
    padding-right: 0px;
  }
  .rate-col {
    display: flex;
    align-items: center;
    height: 20px;
    .ant-rate {
      font-size: 16px;
    }
  }
  .yg-refresh-btn {
    right: 0;
  }
}