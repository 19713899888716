.new-card-modal {
  .form-bg {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:last-child {
      margin-bottom: 0px;
    }
    .ant-form-item {
      width: calc((100% - 24px) / 2);
      &:nth-child(2n) {
        margin-left: 24px;
      }
    }
    .ant-form-item-label {
      width: 112px;
      max-width: 112px;
      flex: unset;
    }
  }
  .without-margin-bottom {
    margin-bottom: 0px;
  }
  .one-line-item {
    .ant-form-item-label {
      width: 132px;
      max-width: 132px;
      flex: unset;
    }
  }
}