.video-list-page {
  .yg-refresh-btn {
    right: 120px;
  }
  .empty-wrap {
    width: 100%;
    height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-section {
    width: 100%;
    min-height: calc(100vh - 250px);
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    position: relative;
    .ant-pagination {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .video-item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 20%;
    margin-bottom: 24px;
    flex-basis: calc(16.66% - 26px);
    margin-right: 26px;
    &:nth-child(6n) {
      margin-right: 0;
    }
    .tag-wrap {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0 10px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background: rgba(0,0,0,0.4);
      border-radius: 4px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
    .video-wrap {
      height: 168px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.05);
      > img {
        max-width: 100%;
        max-height: 168px;
        width: auto;
        border-radius: 4px;
      }
    }
    .name-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      .name {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 10px;
        max-width: 120px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .anticon {
        color: #D9D9D9;
        cursor: pointer;
      }
    }
    .info-wrap {
      margin-top: 6px;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .times { 
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #A1A2AE;
      }
      .anticon-delete {
        cursor: pointer;
        color: #A1A2AE;
        &:hover {
          color: #FF6860;
        }
      }
    }
  }
}
.video-uploader {
  .ant-upload.ant-upload-select {
    width: 300px !important;
    height: 120px !important;
    background-color: #FAFAFA !important;
    border-radius: 2px;
    border: 1px dashed #9E896A !important;
  }
  .click-text {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #815008;
  }
  .video-tips {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8C8C8C;
  }
}