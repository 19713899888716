.login-page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 38px 40px 28px 38px;
  box-sizing: border-box;
  &-section {
    width: 100%;
    height: calc(100vh - 66px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .login-bg {
    position: relative;
    .login-img {
      height: calc(100vh - 66px);
    }
    &-text {
      position: absolute;
      left: 38px;
      &-big {
        bottom: 72px;
        font-size: 32px;
        font-family: PingFang HK-Semibold, PingFang HK;
        font-weight: 600;
        color: #FFFFFF;
      }
      &-small {
        bottom: 35px;
        font-size: 22px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
  
  .login-form {
     width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo-wrap {
      width: 100%;
      text-align: right;
      margin-bottom: 50px;
    }
    .form-wrap {
      width: 435px;
      height: calc(100% - 95px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      .ant-form {
        .ant-form-item-label {
          >label {
            margin-bottom: 6px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #000000;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .title-wrap {
      margin-bottom: 65px;
      font-size: 20px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #7E7E7E;
      .title-text {
        font-size: 24px;
        color: #333333;
      }
    }
    .form-input {
      padding: 0 30px;
      width: 435px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 40px;
      border: 1px solid #9E896A;
      &:focus {
        box-shadow: none;
      }
    }
    .get-code-wrap {
      width: 435px;
      background: #FFFFFF;
      border: 1px solid #9E896A;
      border-radius: 40px;
      box-sizing: border-box;
      padding: 0 30px;
      .code-btn {
        height: 54px;
        border: none;
        border-radius: 0;
        &:focus {
          box-shadow: none;
        }
      }
      .get-code-input {
        width: 280px;
      }
      .get-code-btn {
        width: calc(100% - 280px);
        border-left: 1px solid #9E896A;
        height: 30px;
        background: none;
      }
    }
    .form-operate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: PingFang HK-Light, PingFang HK;
      font-weight: 300;
      color: #000000;
      .forget-text {
        cursor: pointer;
      }
    }
    .btn-wrap {
      text-align: right;
      margin-top: 60px;
    }
    .form-btn {
      width: 232px;
      height: 50px;
      background: #9E896A;
      border-radius: 36px;
      font-size: 16px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}