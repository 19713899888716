.avatar-list-page {
  .avatar-list-header {
    position: relative;
    .yg-refresh-btn {
      right: 120px;
    }
  }
}

.avatar-uploader {
  .ant-upload.ant-upload-select {
    width: 108px !important;
    height: 108px !important;
    background-color: #FAFAFA !important;
    border-radius: 2px;
    border: 1px dashed #9E896A !important;
  }
  .click-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .click-text {
    margin-top: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #815008;
  }
}

.add-avatar-form {
  position: relative;
  .img-tips {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8C8C8C;
  }
}