.class-detail-modal {
  .top-card {
    width: 100%;
    border-radius: 6px;
    background-color: #9E896A;
    padding: 20px;
    margin-bottom: 20px;
    .class-title-box {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255,255,255,0.4);
      > img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      > span {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        max-width: 260px;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
      }
      .difficulty-box {
        position: absolute;
        right: 0px;
        display: flex;
        align-items: center;
        > span {
          color: #FFFFFF;
          font-size: 12px;
          margin-right: 12px;
        }
        .ant-rate {
          color: #FFBD3E;
          font-size: 14px;
        }
      }
    }
    .info-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      .info-item {
        width: 33%;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 12px;
        > span {
          &:first-child {
            color: rgba(255,255,255,0.8);
            margin-right: 12px;
          }
          &:last-child {
            display: inline-block;
            color: #FFFFFF;
            max-width: 80px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
          }
        }
      }
    }
  }
  .other-info-item {
    margin-top: 12px;
    min-height: 20px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #707072;
    &:first-child {
      margin-top: 0px;
    }
    > span:last-child {
      color: #252A3D;
      line-height: 16px;
      display: inline-block;
      max-width: 240px;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;
    background-color: #EBEDF0;
  }
}